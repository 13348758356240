import {List} from "antd";
import {GlobalOutlined, LinkOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {RitaIntegration} from "../../../../components/rita-integration";
import React, {useContext} from "react";
import {WhatsappIntegration} from "../../../../components/whatsapp-integration";
import UserContext from "../../../../context/users";
import {AdminRole, OwnerRole} from "../../../../constants";
import {ZAPIIntegration} from "../../../../components/z-api-integration";
import MyZapIntegration from "../../../../components/my-zap-integration";
import {AgendorIntegration} from "../../../../components/agendor-integration";

export default function Integrations({id, messageApi}) {
    const [user] = useContext(UserContext);

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 2,
            }}
            dataSource={[
                {
                    label: "Link externo",
                    description: "Exponha seu assistente externamente",
                    icon: <GlobalOutlined />,
                    type: "rita"
                },
                {
                    label: "Whatsapp (Oficial)",
                    description: "Publique seu chat no whatsapp",
                    icon: <WhatsAppOutlined />,
                    type: "whatsapp"
                },
                {
                    label: "Whatsapp (Z-API)",
                    description: "Publique seu chat no whatsapp",
                    icon: <WhatsAppOutlined />,
                    type: "z-api"
                },
                {
                    label: "Whatsapp (MyZap)",
                    description: "Publique seu chat no whatsapp",
                    icon: <WhatsAppOutlined />,
                    type: "myZap"
                },
                {
                    label: "Agendor (Agendor)",
                    description: "Realize integrações com seu CRM agendor",
                    icon: <LinkOutlined />,
                    type: "agendor"
                },
                // {
                //     label: "Autenticação",
                //     description: "Integre seu assistente com sistemas de autenticação",
                //     icon: <KeyOutlined/>,
                //     type: "auth"
                // }
            ]}
            renderItem={(item) => <List.Item>
                {item.type === 'rita' &&
                    <RitaIntegration {...item} assistantID={id}
                                     messageApi={messageApi}/>}
                {item.type === 'whatsapp' && user.role === AdminRole && <WhatsappIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'z-api' && user.role === AdminRole && <ZAPIIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'myZap' && (user.role === AdminRole || user.role === OwnerRole) && <MyZapIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {item.type === 'agendor' && (user.role === AdminRole || user.role === OwnerRole) && <AgendorIntegration
                    {...item} assistantID={id} messageApi={messageApi}
                />}
                {/*{item.type === 'auth' &&*/}
                {/*    <AuthIntegration {...item} assistantID={id}*/}
                {/*                     messageApi={messageApi}/>}*/}
            </List.Item>}
        />
    )
}
